$color : #e05b41;

.promo-section {
  background-color: #ffdfd3;
}

.text-promo {
  color: #e05b41;
  font-size: 16pt;
  text-shadow: 1px 2px 2px rgb(250, 250, 250);
}
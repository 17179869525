.ant-select.ant-select-enabled {
  width: 100% !important;
}

.ant-select-selection {
  border: none;
}

.ant-select-open .ant-select-selection {
  // border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  // -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  //         box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-upload-list-picture-card .ant-upload-list-item {
  // width: 100px;
  margin: 0px 7px 8px 0px;
  padding: 0px;
  border: none;
}

.ant-select-selection__rendered {
  margin-left: unset !important;
}

.ant-select-open .ant-select-selection{
  border-color: unset !important;
  -webkit-box-shadow: none !important;
  box-shadow: none;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: unset;
}

.ant-upload.ant-upload-select-picture-card {
  margin-right: unset;
  border: 1px solid #000 !important;
  border-radius: unset !important;
}

.ant-upload-picture-card-wrapper {
  display: unset;
}

.ant-upload-list-picture-card-container {
  margin: 0px 5px 8px 0 !important;
}
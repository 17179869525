.brand-coupon {
  padding-top: 0.5rem;
  &__title {
    font-size: 12px;
    font-weight: 700;
  }

  &__list {
    background-color: #ebecff;
    margin-top: 8px;
    padding: 5px 8px 5px 8px;
    border-radius: 5px;
  }

  &__name {
    font-size: 14px;
    color: #633c97;
    font-weight: 600;
  }
}

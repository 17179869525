.social-share-set-margin {
    .title {
        font-weight: bold;
        font-size: 22px;
    }

    .subtitle {
        color: #9b9b9b;
        font-size: 12px;
        margin-bottom: 25px;
    }

    .price-detail {
        color: #9b9b9b;
        font-size: 13px;
        margin-bottom: 36px;

        .active {
            font-weight: bold;
            color: #333333;
        }
    }

    .data-input {
        .data-input-group {
            margin-bottom: 35px;

            .label.margin {
                color: #9b9b9b;
                font-size: 10px;
            }

            .dig-input {
                border: 0;
                margin-bottom: 5px;
                margin-top: 5px;
                flex: 1;
                // color: #9b9b9b;
                color: #333333;
            }

            .dig-textarea {
                border: 0;
                margin-bottom: 5px;
                margin-top: 5px;
                width: calc(100% + 20px);
                background: #f8f8f8;
                border-radius: 5px;
                margin-left: -11px;
                margin-right: -11px;
                padding: 11px;
                // color: #9b9b9b;
                color: #333333;
            }

            .dig-message-count {
                color: #9b9b9b;
                font-size: 12px;
            }

            .fa {
                color: #9b9b9b;
            }
        }

        .switch-unit {
            color: #9b9b9b;
            padding-bottom: 25px;
            text-align: center;
            cursor: pointer;

            .color-primary {
                color: #633c97;
            }
        }
    }

    .action-footer {
        margin: 0px -14px;

        .custom-btn {
            margin: 0px 14px;
            width: 100%;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            height: 44px;
            font-size: 16px;
            border: 0;
            font-weight: 600;
            transition: ease all 0.3s;

            &:disabled,
            &[disabled] {
                background: #d0d0d0 !important;
                opacity: 1;
                border-color: transparent !important;
                color: white !important;
                box-shadow: none;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            &:hover {
                text-decoration: none;
            }

            &.btn-link {
                background: white;
                color: #633c97;
            }

            &.btn-whatsapp {
                background: #7AD06D;
                color: white;
            }
        }
    }

    .error-bag {
        color: #d00e0e;
        font-size: 12px;
    }
}

.button-outline {
    width: 100%;
    height: 40px;
    border-radius: 22px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    font-weight: 700;
    color: #633c97;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 1;

    &+& {
        margin-left: 12px !important;
    }
}

.social-whatsapp-share {
    width: 32px;
    height: 32px;
    box-shadow: 0px 0px 7px rgba(0,0,0, 0.1), 0px 2px 3px rgba(0,0,0,0.06);
    border-radius: 100px;
    background:#7ad06d;
    text-align: center;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    margin-left:12px;
}
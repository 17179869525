.map-overlayer {
    .location-search-input {
        background: #f4f4f4;
        color: #a9a9a9;
        border-radius: 4px;
        padding: 8px 8px;
        cursor: pointer;
    }

    .location-search-input .fa {
        color: #848484;
        width: 16px;
        font-size: 20px;
        text-align: center;
        margin-right: 10px;
        margin-left: 6px;
    }

    .location-search-input .lsi__holder {
        flex: 1;
        background: transparent;
        border: none;
        color: #333;
    }

    .location-search-input .fa-search {
        color: #808285;
    }

    .btn {
        font-size: 14px;
        font-family: 'Montserrat';
        font-weight: 600;
        padding: 10px 6px;

        span {
            font-size: 12px;
            display: block;
        }
    }

    .btn-primary {
        background: #633c97;
        border-color: #633c97;

        &:active {
            color: #fff !important;
            background-color: darken(#633c97, .1) !important;
            border-color: darken(#633c97, .1) !important;
        }
    }

    .current-location {
        display: flex;
        padding-top: 20px;
        cursor: pointer;

        .fa {
            color: #ff8b28;
            font-size: 16px;
            width: 22px;
            text-align: center;
            padding-top: 2px;
            margin-right: 6px;
        }

        .cl_explain {
            color: #ff8b28;
            font-size: 16px;
        }

        .cl_current {
            color: #747474;
        }
    }
}
.emas {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
}

.gps-commission {
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
}

.desc {
  font-weight: 500;
  font-size: 12px;
}

.stepGPS {
  padding: 12px 0;
  background: #f6f9ff;

  .container {
    display: flex;
  }

  &__container {
    width: 33.33%;

    &-sign {
      display: flex;

      &-line {
        flex: 1 1;

        &-top {
          width: 100%;
          height: 14px;
          border-bottom: 1px solid #979797;
        }

        &-bottom {
          width: 100%;
          height: 14px;
        }
      }

      &-circle {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #dbdbdb;
        color: white;
        line-height: 28px;
        text-align: center;
        font-weight: bold;
      }
    }

    &-title {
      text-align: center;
      font-size: 10px;
      color: #dbdbdb;
    }

    &.active {
      .stepGPS__container-sign {
        &-line {
          &-top {
            border-bottom: 1px solid #633c97;
          }
        }

        &-circle {
          background: #633c97;
        }
      }

      .stepGPS__container-title {
        color: #633c97;
      }
    }
  }
}

.productGPS {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #f3f4f6;

  &__detail {
    display: flex;
    align-items: flex-start;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    padding-right: 20px;
    padding-left: 20px;

    &__desc {
      padding-top: 10px;
    }

    &-image {
      width: 96px;
      height: auto;
      object-fit: cover;
      margin-right: 10px;
    }

    &-content {
      flex: 1 1;
      &-name {
        font-size: 12px;
        color: #292b2c;
      }

      &-qty {
        margin-bottom: 5px;
        span {
          background: #f9bc96;
          color: #693312;
          font-size: 10px;
          border-radius: 3px;
          padding: 2px 6px;
        }
      }

      &-bottom {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &-discount { 
          margin-top: -5px;
          padding: 2px 5px;
          text-align: center;
          border-radius: 3px;
          background: #d32600;
          color: white;
          font-family: Nunito;
          font-size: 8px;
          font-weight: 500;
          display: inline-block;

          &-price {
            display: inline-block;
            color: #9b9b9b;
            text-decoration: line-through;
            font-size: 10px;
            margin-right: 4px;
          }
        }

        &-left {
          &-sellprice {
            color: #9b9b9b;
            font-size: 10px;
          }

          &-price {
            font-weight: bolder;
            margin-bottom: -2px;
          }
        }

        &-right {
          border: none;
          border-radius: 4px;
          padding: 5px 12px;
          background: #633c97;
          color: #ffffff;
          font-size: 12px;
        }
      }
    }
  }

  &__notif {
    &-title {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 15px;
      margin-top: 40px;
    }

    &-text {
      font-size: 14px;
      color: #747474;
      font-weight: normal;
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-cancel {
        width: 50%;
        margin-right: 15px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        height: 44px;
        padding: 0 !important;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
      }

      &-submit {
        width: 50%;
        background-color: #633c97 !important;
        color: #fff !important;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        height: 44px;
        padding: 0 !important;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

@import './../../../static/scss/variable';
@import './../../../static/scss/fixed-layout';

.product-detail-page {
    position: relative;

    .product-slider {
        background: white;

        .main-slider {
            position: relative;

            .main-slider-item {
                width: 100%;
                padding-top: 100%;
                position: relative;

                video,
                .video-holder {
                    width: 100%;
                    height: 100%;
                }

                .video-holder::before {
                    content: " ";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, .3);
                    z-index: 1;
                }

                .play-button {
                    width: 50px;
                    height: 50px;
                    top: 0;
                    left: 0;
                    z-index: 5;
                }

                img,
                video,
                .video-holder {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }
            }

            &:hover {
                .zoom-button {
                    opacity: 1;
                }
            }

            .zoom-button {
                opacity: 0;
                transition: ease all 0.5s;
                font-size: 18px;
                position: absolute;
                bottom: 15px;
                right: 15px;
                z-index: 1;
                cursor: pointer;
                background: rgba(0, 0, 0, .7);
                color: white;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 100px;
            }
        }

        .nav-slider {
            padding-right: 11px;
            
            .nav-slider-item {
                width: 100%;
                padding-top: 90%;
                position: relative;
                border-radius: 8px;
                margin-right: 4px;
                border: 2px solid transparent;
                overflow: hidden;

                .video-holder::before {
                    content: " ";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, .3);
                    z-index: 1;
                }

                .play-button {
                    width: 12px;
                    height: 12px;
                    top: 0;
                    left: 0;
                    z-index: 5;
                }

                img,
                video,
                .video-holder {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    width: 100%;
                }

                transition: border ease .1s;
            }

            .slick-slide {
                cursor: pointer;

                &.slick-current {
                    .nav-slider-item {
                        border: 2px solid $color-primary !important;
                        border-radius: 4px;
                    }
                }
            }

            .slick-cloned {
                display: none;
            }
        }
    }

    .product-name {
        color: #292b2c;
        // font-size: 12px;
        font-weight: 500;
        margin-top: -2px;
        margin-bottom: 14px;
    }

    .brand-name {
        cursor: pointer;
        color: #292b2c;
        font-size: 18px;
        font-weight: 800;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 0;
    }

    .product-commission {
        opacity: .8;
        font-size: 12px;
    }

    .card-divider {
        width: 100%;
        height: 7px;
        background-color: #f4f4f4;
    }

    .product-card {
        background: white;
        padding-top: 2px;
        padding-bottom: 24px;
        // margin-bottom: 8px;

        // &.space-top {
        //     margin-top: 8px;
        // }

        .product-card-title {
            color: #747474;
            font-size: 14px;
            font-weight: 600;
            margin-top: 1em;
            margin-bottom: 1em;
            position: relative;

            .product-card-action {
                display: inline-block;
                right: 0;
                position: absolute;
                cursor: pointer;
                border: 1px solid #633c97;
                color: #633c97;
                border-radius: 4px;
                padding: 4px 5px;
                font-size: 12px;
                transition: all ease .3s;
                -webkit-user-select: none;
                /* webkit (safari, chrome) browsers */
                -moz-user-select: none;
                /* mozilla browsers */
                -khtml-user-select: none;
                /* webkit (konqueror) browsers */
                -ms-user-select: none;
                /* IE10+ */

                &:hover {
                    background: #633c97;
                    color: white;
                }

                &:active {
                    color: #fff !important;
                    background-color: #633c97 !important;
                    border-color: #633c97 !important;
                }
            }
        }

        .feature-disabled {
            opacity: .5;
        }
    }

    .location-search-input {
        background: #f4f4f4;
        color: #a9a9a9;
        border-radius: 4px;
        padding: 8px 8px;
        cursor: pointer;
    }

    .location-search-input .fa {
        color: #848484;
        width: 16px;
        font-size: 20px;
        text-align: center;
        margin-right: 10px;
        margin-left: 6px;
    }

    .location-search-input .lsi__holder {
        flex: 1;
    }

    .location-search-input .fa-search {
        color: #808285;
    }

    .product-stock-information {
        .title {
            margin-bottom: 14px;
            color: #292b2c;
            font-size: 12px;
        }

        .desc {
            color: #747474;
            text-align: right;
        }
    }

    .product-description,
    .product-bonus,
    .product-review {
        color: #747474;
    }

    .product-price {
        color: #292b2c;
        font-size: 20px;
        font-weight: 800;
        margin-top: 9px;
    }

    .background-primary {
        background-color: #633c97 !important;
    }

    .expandable {
        .expandable-content {
            transition: all ease .5s;
            max-height: fit-content;
            overflow: hidden;
        }

        .toggle-expand {
            display: none;
        }

        .toggle-collapse {
            display: block;
        }

        &.collapsed {
            .expandable-content {
                max-height: calc(21px + 3em);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;

                &.zero {
                    max-height: 0px;
                }
            }

            .toggle-expand {
                display: block;
            }

            .toggle-collapse {
                display: none;
            }
        }

        .toggle-button {
            color: $color-primary;
            text-align: center;
            padding-top: 12px;
            // padding-bottom: 12px;
            cursor: pointer;
            border: none;
            background: white;
            width: 100%;
        }
    }

    .product-review {
        .reviewer {
            color: #292b2c;
        }

        .reviewer-desc {
            color: #9b9b9b;
            font-size: 10px;
        }

        .review-indicator {
            &.fire {
                color: #E38C32;
            }
        }
    }

    .product-action-footer {

        padding-bottom: 8px;
        padding-top: 8px;
        transition: ease all .3s;

        .btn {
            font-size: 14px;
            font-family: 'Montserrat';
            font-weight: 700;
            padding: 3px 6px;

            span {
                font-size: 10px;
                display: block;
            }
        }

        .btn-primary {
            background: #633c97;
            border-color: #633c97;

            &:active {
                color: #fff !important;
                background-color: darken(#633c97, .1) !important;
                border-color: darken(#633c97, .1) !important;
            }
        }

        .btn-outline-primary {
            border-color: #633c97;
            color: #633c97;

            &:hover {
                background: #633c97;
                color: white;
            }

            &:active {
                color: #fff !important;
                background-color: #633c97 !important;
                border-color: #633c97 !important;
            }
        }

        &.sticky {
            @extend .fixed-position-state;
            position: fixed;
            display: block;
            bottom: 0;
            width: 100%;
            left: 0;
            z-index: 1;
            margin-bottom: 0;
            margin: 0 auto;
            right: 0;
            box-shadow: 0 -5px 10px -4px rgba(0, 0, 0, .2);
        }

        .button-action-cart {
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.2);
            padding: 10px 10px;
            text-align: center;
            position: relative;
            cursor: pointer;

            .ant-badge-count {
                height: 14px;
                line-height: 14px;
                min-width: 14px;
                padding: 0;
                font-size: 11px;
                background: #f02b01;
                box-shadow: none;
                font-weight: 600;

                & .current {
                    line-height: 15px !important;
                }
            }
        }
    }

    .single-order-notice {
        cursor: pointer;
    }

    .change-product-brand-drawer {
        .title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 15px;
            margin-top: 40px;
        }

        .message {
            font-size: 14px;
            color: #747474;
        }

        .btn {
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            height: 44px;
            padding: 0;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;

            &.btn-custom-primary {
                background: #633c97;
                color: #fff;
            }
        }
    }
}

.slider-video {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.ril__image {
    text-align: center !important;
}

.ril-errored {
    z-index: -1;
}

.button-copy-desc {
    display: inline-block;
    right: 0;
    cursor: pointer;
    border: 1px solid #633c97;
    color: #633c97;
    border-radius: 4px;
    padding: 4px 5px;
    font-size: 12px;
    transition: all ease .3s;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */

    &:hover {
        background: #633c97;
        color: white;
    }

    &:active {
        color: #fff !important;
        background-color: #633c97 !important;
        border-color: #633c97 !important;
    }
}

.commission-title {
    margin-bottom: 14px;
    color: #292b2c;
    font-size: 12px;
}

.commission-desc {
    color: #747474;
    text-align: right;
}
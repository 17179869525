/*Fonts*/

// @font-face {
// 	font-family: 'Montserrat';
// 	src: url(../fonts/Montserrat/Montserrat-Regular.ttf);
// }

// @font-face {
// 	font-family: 'Montserrat';
// 	src: url(../fonts/Montserrat/Montserrat-Bold.ttf);
// 	font-weight: 700;
// }

// @font-face {
// 	font-family: 'Montserrat';
// 	src: url(../fonts/Montserrat/Montserrat-Medium.ttf);
// 	font-weight: 600;
// }

// @font-face {
// 	font-family: 'Roboto';
// 	src: url(../fonts/Roboto/Roboto-Regular.ttf);
// }

// @font-face {
// 	font-family: 'Roboto';
// 	src: url(../fonts/Roboto/Roboto-Black.ttf);
// 	font-weight: 700;
// }

// @font-face {
// 	font-family: 'Roboto';
// 	src: url(../fonts/Roboto/Roboto-Medium.ttf);
// 	font-weight: 600;
// }


// @font-face {
// 	font-family: 'Nunito';
// 	src: url(../fonts/Nunito/Nunito-Regular.ttf);
// }

// @font-face {
// 	font-family: 'Nunito';
// 	src: url(../fonts/Nunito/Nunito-Bold.ttf);
// 	font-weight: 700;
// }

// @font-face {
// 	font-family: 'Nunito';
// 	src: url(../fonts/Nunito/Nunito-Light.ttf);
// 	font-weight: 600;
// }

/* Typography */

.font-size-xxxl
{
	font-size: 36px;
}

.font-size-xxl {
	font-size: 28px;
}

.font-size-xl {
	font-size: 20px;
}

.font-size-l {
	font-size: 18px;
}

.font-size-m {
	font-size: 16px;
}

.font-size-s {
	font-size: 14px;
}

.font-size-xs {
	font-size: 12px;
}

.font-size-xxs {
	font-size: 10px;
}

.font-size-xxxs {
	font-size: 8px;
}

.text-white {
	color: white;
}

.bold {
    font-weight: 500 !important;
}

.bolder {
	font-weight: 600 !important;
}

.boldest {
    font-weight: 700 !important;
}
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  border: solid 0.8px #9b9b9b;
  width: 2px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox input~.checkmark,
.container-checkbox .checkmark {
  background-color: #f4f5fb;
  border-radius: 5px;
  height: 22px;
  width: 22px;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark,
.container-checkbox.checked .checkmark,
.container-checkbox.indeterminate .checkmark {
  background-color: #633c97 !important;
  border: unset !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: unset;
}

/* Show the checkmark when checked */
.container-checkbox {
  input:checked~.checkmark:after {
    display: block;
  }

  &.checked,
  &.indeterminate {
    .checkmark:after {
      display: block;
    }
  }

  &.checked {
    .checkmark:after {
      left: 7px;
      top: 2px;
      width: 8px;
      height: 14px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

    }
  }

  &.indeterminate {
    .checkmark:after {
      left: 6px;
      top: 10px;
      width: 10px;
      height: 0px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}

/* Style the checkmark/indicator */
.container-checkbox {
  .checkmark:after {
    left: 7px;
    top: 2px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
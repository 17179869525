// Hardcoded to change message pop up style
svg {
  vertical-align: unset !important;
}

.ant-message-notice-content {
  background: #633c97 !important;
}

.ant-message {
  color: white;
  top: 36px;
  z-index: 1051;
}
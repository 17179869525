.product-card-grid {
  position: relative;
  bottom: 150px;
  width: 100%;
  z-index: 2;
  left: 0;
  right: 0;
}

.add-to-catalog {
  position: absolute;
  width: 20px;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 4px;
  background-color: #FFF;
  z-index: 1;
}
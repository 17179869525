.filter {
  background-color: #fff;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  // cursor: pointer;
  // div {
  //   cursor: pointer;
  // }

  .reset-button {
    color: #633c97;
    text-align: right;
    padding-bottom: 10px;

    span {
      cursor: pointer;
    }
  }

  &__options {
    input[type="radio"] {
      display: none;
      &:not(:disabled) ~ label {
        cursor: pointer;
      }
      &:disabled ~ label {
        color: hsla(150, 5%, 75%, 1);
        border-color: hsla(150, 5%, 75%, 1);
        box-shadow: none;
        cursor: not-allowed;
      }
    }
    label {
      height: 100%;
      display: block;
      background: white;
      border: 2px solid #dddddd;
      border-radius: 5px;
      padding: 8px;
      margin-right: 6px;
      //margin: 1rem;
      text-align: center;
      position: relative;
    }
    input[type="radio"]:checked + label {
      background: #633c97;
      color: #fff;
      border-color: #633c97;
    }
  }

  &__sort-options {
    $radioSize: 20px;
    $radioBorder: #d1d7e3;
    $radioActive: #633c97;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;

    input {
      display: none;
      & + span {
        cursor: pointer;
        line-height: $radioSize;
        height: 7px;
        padding-left: $radioSize;
        display: block;
        position: relative;
        &:not(:empty) {
          padding-left: $radioSize + 8;
        }
        &:before,
        &:after {
          content: "";
          width: $radioSize;
          height: $radioSize;
          display: block;
          border-radius: 50%;
          left: 0;
          top: 0;
          position: absolute;
        }
        &:before {
          background: $radioBorder;
          transform: scale(1.02);
        }
        &:after {
          background: #fff;
          transform: scale(0.4);
          transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        }
      }
      &:checked + span {
        cursor: pointer;
        &:before {
          transform: scale(1.04);
          background: $radioActive;
        }
        &:after {
          transform: scale(0.4);
          transition: transform 0.3s ease;
        }
      }
    }
  }
}

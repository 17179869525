 // Dirty work to fixed desktop view
 // Once upon a time Rinso tell a story, that dirty is good.

 @media (min-width: 601px) {

     #fc_frame.fc-widget-small.h-open-container,
     #fc_frame.h-open-container {
         max-width: 375px !important;
         margin: 0 auto !important;
     }

     #pop-up {
         max-width: 375px;
         margin: 0 auto;
     }

     body {
         max-width: 375px;
         margin: 0 auto;
     }

     #root {
         max-width: 375px;
         margin: 0 auto;
     }

     #floating-banner {
         max-width: 375px;
         margin: 0 auto;
     }

     #fixed-width {
         max-width: 375px;
         margin: 0 auto;
     }

     .react-swipeable-view-container {
         max-width: 375px;
         margin: 0 auto;
     }

     .fixed-position-state {
         max-width: 375px;
         margin: 0 auto;
     }
 }
.product-detail {
    position: relative;
    height: 100%;

    .product-slider {
        margin-bottom: 12px;
    }

    .product-qty {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .product-name {
        .likes {
            font-size: 18px;
        }
    }

    .product-price {
        margin-bottom: 11px;

        .title {
            color: #999999;
        }

        .price {
            font-size: 18px;

            span {
                font-size: 14px;
                font-style: italic;
                color: #999999;
            }
        }
    }

    .product-commission {
        margin-bottom: 11px;

        .title {
            font-size: 12px;
            color: #9b9b9b;
        }

        .commission {
            border-radius: 20px;
            background-color: #7ed321;
            color: #ffffff;

            .price-icon {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                text-align: center;
                background-color: #45c563;
            }

            .price {
                padding: 1px 6px;
            }
        }
    }

    .product-points {
        margin-bottom: 11px;

        .title {
            font-size: 12px;
            color: #9b9b9b;
        }

        .points {
            color: #52c711;
        }
    }

    .product-stock {
        color: #707070;
        margin-top: 17px;
        margin-bottom: 17px;
    }

    .product-desc {
        color: #707070;
        text-align: justify;
    }

    .read-more {
        margin-bottom: 17px;
    }

    .product-footer {
        .add-to-cart {
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.08), 0 0px 2px 0px rgba(0, 0, 0, 0.2);
        }

        .buy-button {
            // .price {}

            .description {
                font-size: 12px;
            }
        }
    }

    .product-bottom-panel {
        width: 100%;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 8px;

        .buy-now {
            border-radius: 8px !important;
        }

        .add-to-cart {
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        }

        .border-color-primary {
            border-color: #633c97 !important;
            color: #633c97 !important;
        }

        .background-color-primary {
            border-color: #633c97 !important;
        }

        .btn {
            padding: 0.375rem 0.75rem;

            &.btn-block {
                margin-top: 0;
            }
        }
    }
}

.number-input-spinner {
    .nis-operator {
        background: #ECFAFD;
        width: 30px;
        border: 1px solid #633c97;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 3px;
        text-align: center;
        line-height: 25px;
        font-size: 20px;
        color: #633c97;
        cursor: pointer;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.disabled {
            background: #cacaca;
            border: transparent;
            color: #8e8e8e;
            cursor: auto;
        }
    }

    .nis-input {
        background: #f4f5fb;
        color: #707070;
        padding: 4px 8px;
        text-align: center;
        border: none;
        width: 80px;
    }
}

.radio-label-component {
    .option-list {
        display: flex;
        flex-wrap: wrap;
    }

    .option-item {
        border: 1px solid #9b9b9b;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 4px;
        text-align: center;
        padding: 6px 20px;
        cursor: pointer;
        transition: ease all .3s;

        &.selected {
            border-color: #633c97;
            background: #ECFAFD;
            color: #633c97;
        }
    }
}

.single-order-button {
    font-size: 16px;
    font-weight: 600;
    background: #633c97 !important;
    padding: .7em !important;
    transition: ease all .3s;

    &:disabled,
    &[disabled] {
        opacity: 0.5;
        background-color: grey !important;
        // background-color: #cccccc !important;
        // color: #666666 !important;
    }
}

.cart-total-badge {
    background: #d0021b;
    color: white;
    border-radius: 100px;
    font-size: 10px;
    font-weight: bold;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20%;
    top: 15%;
    text-align: center;
    line-height: 15px;
}
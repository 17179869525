.ant-btn-primary {
  background-color: #633c97;
  border-color: #633c97;
  
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background-color: #633c97;
  border-color: #633c97;
  color: #fff;
}

.ant-btn:hover, .ant-btn:focus{
  border-color: unset;
}

.w-toolbar {
    position: relative;

    &.shadow {
        box-shadow: 0 0.1rem .2rem rgba(0, 0, 0, 0.09) !important;
    }

    &.w-toolbar-fixed {
        position: fixed;
        top: 0;
        z-index: 100;
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

        &.bg-fixed-transparent {
            .w-toolbar-main {
                background: white;

                .w-toolbar-content {
                    color: #333;
                }
            }

            .w-back {
                filter: none;
            }
            .w-delete {
              background-color: rgb(74, 26, 135);
            }
        }
    }

    &.bg-fixed-transparent {
        .w-back {
            filter: brightness(10);
        }
    }

    &.bg-transparent,
    &.bg-fixed-transparent {
        .w-toolbar-main {
            background: transparent;
        }

        .w-toolbar-main {

            .action-button,
            .w-toolbar-content {
                color: white;
                
            }

            .ant-badge-count {
                height: 14px;
                line-height: 14px;
                min-width: 14px;
                padding: 0;
                font-size: 11px;
                background: #f02b01;
                box-shadow: none;
                font-weight: 600;
        
                & .current {
                    line-height: 15px !important;
                }
            }

            .ant-badge {
                font-size: 11px;
            }

            .toolbar-search {
                background: white;
            }
        }

        .icon-label {
            color: white;
        }

        .action-button img {
            filter: brightness(0) invert(1);
        }
    }

    .icon-label {
        font-size: 8px;
    }

    .icon-group {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .w-toolbar-main {
        display: flex;
        height: 45px;
        min-height: 45px;
        padding: 0 20px;
        align-items: center;
        background: white;
        transition: all ease .3s;

        &>div {
            // margin-right: 3px;
            margin-left: 6px;
            left: 3px;

            &.icon-group {
                margin-left: 12px;
            }

            .ant-spin {
              left: 2px;
            }
        }

        .action-button {
            font-size: 18px;
            color: #9b9b9b;
            min-width: 32px;
            text-align: center;
            cursor: pointer;
        }

        .ant-badge-count {
            height: 14px;
            line-height: 14px;
            min-width: 14px;
            padding: 0;
            font-size: 10px;
            background: #f02b01;
            box-shadow: none;
            font-weight: 600;
    
            & .current {
                line-height: 15px !important;
            }
        }

        .ant-badge {
            font-size: 11px;
        }

        &.with-back-button {

            .w-back,
            .w-back-balancer {
                display: flex;
            }
        }

        .w-back,
        .w-back-balancer {
            display: none;
            width: 10px;
            text-align: center;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            align-content: center;
            margin-right: 14px;

            img {
                width: 10px;
            }
        }

        .w-toolbar-content {
            // flex: 1;
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            font-family: 'Montserrat', sans-serif;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .toolbar-search {
            height: 34px;
            background: #f2f2f2;
            display: flex;
            align-items: center;
            border-radius: 5px;

            .search-icon {
                color: #cccccc;
                padding-left: 13px;
                padding-right: 10px;
            }

            .search-placeholder {
                height: 16px;
            }
        }
    }
}

.bottom-cartt {
    .ant-badge-count {
        height: 14px;
        line-height: 14px;
        min-width: 14px;
        padding: 0;
        font-size: 10px;
        background: #AA0011;
        box-shadow: none;
        font-weight: 600;

        & .current {
            line-height: 15px !important;
        }
    }
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -10px !important;
}
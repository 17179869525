.product-card-main {
  // width: 104px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  // height: 225px;
  height: 245px;

  &__shape {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__gps {
    position: absolute;
    bottom: 88px;
    left: 10px;
  }

  &__commission {
    font-size: 9px;
    color: #fff;
    position: absolute;
    bottom: 2px;
    left: 8px;
  }

  &__name {
    padding-top: 10px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
  }

  &__badge {
    padding-top: 6px;
    span {
      background-color: #f9bc96;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 3px;
      font-size: 10px;
      color: #693312;
    }
  }

  &__price {
    padding-top: 6px;

    .currency {
      font-size: 10px;
      font-weight: 700;
      padding-right: 4px;
    }
    .price {
      font-weight: 700;
    }
  }


  &__badge-discount {

    span {
      background-color: red;
      padding: 2px;
      margin-left: 10px;
      border-radius: 3px;
      font-size: 10px;
      color: #fff;
    }

    del {
      color: #9b9b9b;
    }
  }
}
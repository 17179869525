.product-list-brand {
  padding-bottom: 3.4rem;
  padding-top: 1rem;
  background-color: #FFF;
}

.search-mansonry {
  // columns: 2;
  // column-gap: 20px;
  padding: 0px 20px;
  display: flex;
  margin-left: -20px;
}

.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
}

.empty-product {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50%;
  background-color: #FFF;
}

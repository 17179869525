@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Nunito:400,600,700&display=swap");
@import "~react-modal-video/scss/modal-video.scss";

/* Woobiz Assets */

$color-primary: #633c97;
$color-primary-light: #9b4aab;
$color-primary-inactive: #ECFAFD;
$color-secondary: #f12b00;
$color-tertiary: #9b9b9b;
$color-alternate: #45c563;
$color-light-pink: #ef7489;
$color-gray: #eeeced;
$color-pink: #ffefef;
$color-mist: #364466;
$color-inactive-button: #f4f5fb;
$color-active-button: #ECFAFD;
$color-orange: #ff8b28;

$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius-large: 8px;
$border-radius-largest: 10px;

// Imported SCSS
@import "_input-text.scss";
@import "_typography.scss";
@import "_product-detail.scss";
@import "_product-card.scss";
@import "_custom-font.scss";
@import "_fixed-layout.scss";
@import "_slider.scss";
@import "antd/antd-message";
@import "favourite-button/favourite-button";
@import "popup-banner/popup-banner";
@import "promotion-section/promo-section";
@import "antd/antd-divider";
@import "_wooniversity.scss";
@import "antd/antd-tabs";
@import "antd/antd-select";
@import "antd/antd-input";
@import "antd/antd-checkbox";
@import "_checkbox.scss";
@import "antd/antd-button";
@import "antd/antd-btn";
@import "antd/antd-modal";
@import "antd/antd-spin";
@import "antd/antd-radio";

.font-heading {
  font-family: "Montserrat", sans-serif !important;
  font-display: swap;
}

.font-content {
  font-family: "Nunito", sans-serif;
  font-display: swap;
}

html,
body {
  height: 100%;
  background-color: #ebebeb;
  @extend .font-content;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  /* Firefox 64 */
  // scroll-behavior: smooth;
}

body {
  // font-family: "Nunito"-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

button,
textarea,
input {
  outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  display: none;
}

.font-color-primary {
  color: $color-primary;
}

.font-color-alaternate {
  color: $color-alternate;
}

.font-color-secondary {
  color: $color-secondary;
}

.font-color-tertiary {
  color: $color-tertiary;
}

.font-color-pricing {
  color: #d77247;
}

.font-color-orange {
  color: $color-orange;
}

.wrapper-img {
  position: relative;
}

.border-color-primary {
  border: 1px solid $color-primary;
}

.background-color-primary {
  background-color: $color-primary !important;
}

.background-color-primary-light {
  background-color: $color-primary-light !important;
}

.background-color-secondary {
  background-color: $color-secondary !important;
}

.background-color-primary-inactive {
  background-color: $color-primary-inactive;
}

.background-color-alaternate {
  background-color: $color-alternate;
}

.background-color-tertiary {
  background-color: $color-tertiary;
}

.background-color-mist {
  background-color: $color-mist;
}

.background-color-inactive-button {
  background-color: $color-inactive-button;
}

.background-disabled {
  background-color: #dbdbdb;
}

.background-body {
  background-color: #f3f4f6;
}

.background-white {
  background-color: #ffffff;
}

.background-black {
  background-color: #000;
}

.background-gradient-primary {
  background-image: linear-gradient(#8a6db1, $color-primary);
}

.background-gradient-primary-to-right {
  background-image: linear-gradient(to right, #8a6db1, $color-primary);
}

.background-gradient-pink-to-right {
  background-image: linear-gradient(
    to right,
    $color-primary-inactive,
    $color-light-pink
  );
}

.background-content-disable {
  background-color: $color-gray;
}

.background-transparent {
  background-color: transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #333333;
  line-height: inherit;
  font-weight: bold;
}

h1,
.h1 {
  font-size: 34px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 26px;
}

h4,
.h4 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 14px;
}

a {
  .bottom-nav {
    text-decoration: none;
  }

  color: #333333;
}

p {
  line-height: 22px;
}

.line-through {
  text-decoration: line-through;
}

/* end */

/*padding*/

.padding-xxs {
  padding: 5px !important;
}

.padding-xs {
  padding: 10px !important;
}

.padding-s {
  padding: 15px !important;
}

.padding-m {
  padding: 20px !important;
}

.padding-l {
  padding: 25px !important;
}

.padding-xl {
  padding: 60px !important;
}

/*padding bottom*/

.padding-bottom-xxs {
  padding-bottom: 5px !important;
}

.padding-bottom-xs {
  padding-bottom: 10px !important;
}

.padding-bottom-s {
  padding-bottom: 15px !important;
}

.padding-bottom-m {
  padding-bottom: 20px !important;
}

.padding-bottom-l {
  padding-bottom: 25px !important;
}

.padding-bottom-xl {
  padding-bottom: 60px !important;
}

.padding-bottom-xxl {
  padding-bottom: 80px !important;
}

/*padding top*/

.padding-top-xxs {
  padding-top: 5px !important;
}

.padding-top-xs {
  padding-top: 10px !important;
}

.padding-top-s {
  padding-top: 15px !important;
}

.padding-top-m {
  padding-top: 20px !important;
}

.padding-top-l {
  padding-top: 25px !important;
}

.padding-top-xl {
  padding-top: 60px !important;
}

.padding-top-xxl {
  padding-top: 150px !important;
}

/*padding left*/

.padding-left-xxs {
  padding-left: 5px !important;
}

.padding-left-xs {
  padding-left: 10px !important;
}

.padding-left-s {
  padding-left: 15px !important;
}

.padding-left-m {
  padding-left: 20px !important;
}

.padding-left-l {
  padding-left: 25px !important;
}

.padding-left-xl {
  padding-left: 60px !important;
}

.padding-left-xxl {
  padding-left: 160px !important;
}

/* padding right */

.padding-right-xxs {
  padding-right: 5px !important;
}

.padding-right-xs {
  padding-right: 10px !important;
}

.padding-right-s {
  padding-right: 15px !important;
}

.padding-right-m {
  padding-right: 20px !important;
}

.padding-right-l {
  padding-left: 25px !important;
}

.padding-right-xl {
  padding-left: 60px !important;
}

.padding-right-xxl {
  padding-left: 100px !important;
}

/* No padding */

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

/* Addition Hacks for Margin Bottom when position fixed present */
.margin-bottom-xxxl {
  margin-bottom: 100px;
}

/* No margin */

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.container {
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
}

.navbar {
  background-color: $color-primary;
}

.carousel-inner {
  .carousel-item {
    img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.carousel-indicators {
  li {
    background-color: #633c97;
    border-radius: $border-radius-large;
    border: solid 0.5px #633c97;
    width: 11px;
    height: 11px;
    opacity: unset;

    &.active {
      background-color: #f12b00;
      border-radius: $border-radius-large;
      border: solid 1px #f12b00;
      width: 32px;
      height: 11px;
    }
  }
}

.btn {
  padding: 12px 24px;
}

.btn-link {
  margin: -12px -24px;
}

.form-control {
  border-radius: 2px;
  border: solid 1px #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-set-margin {
  border-radius: 2px;
  padding-left: 5px;
  color: #000;
  font-weight: 700;
  border: solid 1px #cccccc;
  // padding-bottom: 10px;
  width: 330px;
}

.input-placeholder {
  ::placeholder {
    color: #cccccc;
    opacity: 1;
    /* Firefox */
  }
}

.divider {
  width: 100%;
  height: 8px;
  background-color: #f4f4f4;
}

.divider-banner {
  width: 100%;
  border: solid 1px rgba(241, 241, 241, 0.5);
}

.divider-header {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  top: 10px;
}

.divider-vertical {
  border-left: 1px solid #e5e5e5;
  height: 45px;
}

.border-radius-small {
  border-radius: $border-radius-small;
}

.border-radius-medium {
  border-radius: $border-radius-medium;
}

.border-radius-large {
  border-radius: $border-radius-large;
}

.border-radius-largest {
  border-radius: $border-radius-largest;
}

// Checkbox Custom

input[type="checkbox"] {
  transform: scale(2);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 3px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input:checked ~ .checkmark {
  background-color: $color-primary;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}

.label-checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 8px;
  top: 1px;
  width: 10px;
  height: 18px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Radio Button Custom Size
.custom-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkedradio {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
input:checked ~ .checkedradio {
  background-color: $color-primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkedradio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
input:checked ~ .checkedradio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkedradio:after {
  // top: 9px;
  // left: 9px;
  // width: 8px;
  // height: 8px;
  // border-radius: 50%;
  // background: white;
  top: 6.5px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Style for thank you page countdown container */
.countdown-style {
  background-color: #f8f8f8;
  border: solid 2px #d3e0cf;
  border-radius: 5px;
  padding: 5px;
}

/* Style for thank you page warning container */
.thankyou-warning {
  background-color: #fef7da;
  border: solid 2px #e7e6b8;
  border-radius: 5px;
  padding: 15px;
}

// OWL
.owl-carousel {
  touch-action: manipulation;
}

// Ordered List & Modal
.modal-body {
  padding: 0.8rem;
}

.modal-dialog {
  top: 10%;
}

.modal-open {
  padding-right: 0px !important;
}

// Collapsible

// The main container element
.Collapsible {
  background-color: $color-primary-inactive;
}

//The content within the collaspable area
.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid $color-tertiary;
  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: $color-tertiary;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: $color-primary;
  color: white;

  &:after {
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

//PaymentList fix for safari
.pb-safari-scroll {
  padding-bottom: 5rem !important;
}

// .slick-dots {
//     position: relative !important;
//     bottom: 210px !important;
// }

// .slick-dots li.slick-active button:before {
//     opacity: 1 !important;
//     font-size: 12px;
//     color: #633c97 !important;
// }

.slick-thumb li img {
  -webkit-filter: grayscale(100%) !important;
}

.slick-thumb li.slick-active img {
  filter: grayscale(0) !important;
}

// Woobiz Logo Style

.woobiz-logo {
  // display: block;
  margin: 0 auto;
}

.woobiz-illustration {
  display: block;
  margin: 0 auto;
}

// Setup preferences option style

ul.btn-option {
  list-style: none;
  padding: 20px;
}

ul.btn-option li {
  display: inline;
}

ul.btn-option li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 10px;
  white-space: nowrap;
  margin: 4px 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.btn-option li label {
  padding: 8px 18px;
  cursor: pointer;
}

ul.btn-option li input[type="checkbox"]:checked + label {
  border: 2px solid #633c97;
  background-color: #633c97;
  color: #fff;
  transition: all 0.2s;
}

ul.btn-option li input[type="checkbox"] {
  display: absolute;
}

ul.btn-option li input[type="checkbox"] {
  position: absolute;
  visibility: hidden;
}

ul.btn-option li input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff;
}

// Selling Preference

ul.btn-selling {
  list-style: none;
  padding: 20px;
}

ul.btn-selling li {
  display: inline;
}

ul.btn-selling li label {
  display: inline-block;
  background-color: #fff;
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 10px;
  white-space: nowrap;
  margin: 4px 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.btn-selling li label {
  padding: 14px 11px;
  cursor: pointer;
}

ul.btn-selling li input[type="checkbox"]:checked + label {
  border: 2px solid #633c97;
  color: #fff;
  transition: all 0.2s;
}

ul.btn-selling li input[type="checkbox"] {
  display: absolute;
}

ul.btn-selling li input[type="checkbox"] {
  position: absolute;
  visibility: hidden;
}

ul.btn-selling li input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff;
}

// Target preferences style

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 20px 0;
  position: relative;
  border-radius: 8px;

  label {
    padding: 12px 10px;
    width: 100%;
    display: block;
    text-align: left;
    color: #000;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 2px #eaecee;

    &:before {
      width: 10px;
      height: 10px;
      content: "";
      background-color: #633c97;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      // transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #4f1f8b;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #410a76;
      border-color: #530c92;
      box-shadow: 0px 0px 8px #410a76;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    border-radius: 8px;
  }
}

.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  border-radius: 9px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}

// Button style
.padding-btn {
  padding: 9px;
}

// Header shadow
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

// Tabs
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #633c97;
  background-color: #f3f4f6;
  font-weight: bolder;
  div.title {
    background-color: #fff;
  }
}

// .active + .divider-commission {
//     background: transparent !important;
// }

// .nav-tabs > a.nav-link{
//     &:nth-child(2) {
//         background: black !important;
//     }
// }

.divider-commission {
  width: 1px;
  background: rgba(116, 116, 116, 0.5);
  height: 15px;
  margin: auto;
}

.nav-tabs .nav-link {
  border: none;
  background-color: #f3f4f6;
  color: #747474;
  border-radius: 0;
  padding: 0;
  div.space {
    padding: 3px 3px;
    // border-right: 1px solid $color-primary;
    div.title {
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
    }
  }
  &:last-child {
    div.space {
      border-right: 0;
    }
  }
}

.nav-tabs {
  border-bottom: none;
  border-radius: 0.25rem;
}

.react-joyride__tooltip {
  width: 200px !important;
  height: 10px !important;
}

#pop-up {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10000;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  margin: 0 auto;
  text-align: center;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6);
  /* Black w/ opacity */
}

.table-responsive::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

table.table.tableGraph th {
  text-align: center;
  vertical-align: middle;
  min-width: 110px;
  padding: 0px;
  font-size: 12px;

  .active {
    color: $color-alternate;
  }

  &:first-child {
    min-width: 50px;
  }
}

table.table.tableGraph th.active {
  color: $color-alternate;
}

table.table.tableGraph th.inActive {
  color: $color-tertiary;
}

table.table.tableGraph td {
  border: 0;

  &:first-child {
    padding: 3px;
  }
}

table.table.tableGraph tr.borderTableGraph td.active {
  border-bottom: 3px solid $color-alternate;
}

table.table.tableGraph tr.borderTableGraph td.inActive {
  border-bottom: 3px solid $color-tertiary;
}

table.table.tableGraph tr.borderTableGraph td.iconWasActive {
  border-bottom: none;
  background-image: url("../images/navigations/circle-green.svg");
  background-position-y: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 10px;
  padding: 0.4rem;
}

table.table.tableGraph tr.borderTableGraph td.iconActive {
  border-bottom: none;
  background-image: url("../images/navigations/verified-green.svg");
  background-position-y: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 10px;
  padding: 0.7rem;
}

table.table.tableGraph tr.borderTableGraph td.iconInActive {
  border-bottom: none;
  background-image: url("../images/navigations/circle-gray.svg");
  background-position-y: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 10px;
  padding: 0.7rem;
}

table.table.tableGraph th.bonusWasActive div div {
  color: $color-alternate;
}

table.table.tableGraph th.bonusActive div div {
  color: $color-primary;
  border: 1px solid $color-primary;
  background: rgba(99, 59, 151, 0.5);
}

table.table.tableGraph th.bonusInActive div div {
  color: $color-tertiary;
  border: 1px solid $color-tertiary;
}

.no-box-shadow {
  box-shadow: none !important;
}

.notice-product .sub-notice-product {
  color: white;
  font-size: 10px;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
}

.notice-product .sub-notice-product:last-child {
  margin-right: 0;
}

.notice-product .stock-limit {
  background-color: $color-secondary;
}

.notice-product .offer-limit {
  background-color: $color-primary;
}

textarea.form-control {
  border: none !important;
  background-color: #f8f8f8 !important;
  resize: none;
  overflow: hidden;
}

.button-message button {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  text-align: left;
  box-shadow: none !important;

  &:first-child {
    margin-left: 0;
  }
}

.e {
  background-color: #8ebd0e;
  padding: 10px;
}

.icon-input-control {
  position: absolute;
  right: 0px;
  bottom: 5px;
  font-size: 18px;
}

table.table.tableTrack tr td {
  min-width: 15px;
  height: 10px;
  padding: 0;
  font-size: 10px;
  text-align: center;
}

table.table.tableTrack tr td.iconTableTrack {
  width: 25px !important;
}

table.table.tableTrack tr.borderTableTrack td.borderInactive {
  border-bottom: 2px solid $color-tertiary;
  padding-top: 0.8rem;
}

table.table.tableTrack tr.borderTableTrack td.borderActive {
  border-bottom: 2px solid $color-alternate;
  padding-top: 0.8rem;
}

table.table.tableTrack tr td.inActive {
  color: $color-tertiary;
}

table.table.tableTrack tr td.active {
  color: $color-alternate;
}

table.table.tableTrack tr td.done {
  color: $color-alternate;
}

table.detailTrack tr td.detailTrackShort {
  width: 10px;
  height: 25px;
}

table.detailTrack tr td.detailTrackShort2 {
  width: 10px;
  height: 10px;
}

table.detailTrack tr td.detailTrackShort.detailTrackBorder {
  border-right: 2px solid $color-tertiary;
}

table.detailTrack tr td.detailTrackShort2.detailTrackBorder {
  border-right: 2px solid $color-tertiary;
}

table.detailTrack tr td.iconActive {
  border-bottom: none;
  background-image: url("../images/navigations/circle-green.svg");
  background-position-x: 5px;
  background-position-y: bottom;
  background-size: 12px auto;
  background-repeat: no-repeat;
  padding: 0.7rem;
}

table.detailTrack tr td.iconInActive {
  border-bottom: none;
  background-image: url("../images/navigations/circle-gray.svg");
  background-position-x: 6px !important;
  background-position-y: bottom;
  background-size: 11px auto;
  background-repeat: no-repeat;
  padding: 0.7rem;
  padding-top: 0px;
}

.couponTab ol,
.couponTab ul {
  padding-left: 0.75rem;
}

.react-html5-camera-photo video {
  width: 100%;
}

.demo-image-preview > img {
  width: 100%;
}

.line-height-4 {
  line-height: 1.5rem;
}

.react-html5-camera-photo {
  #container-circles {
    position: fixed;
    bottom: 80px;
    z-index: 9999;

    #outer-circles {
      height: 70px;
      width: 70px;

      #inner-circle {
        top: 35px;
      }
    }
  }
}

.search-clear-button {
  position: absolute;
  right: 10px;
  top: 7px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: white;
}

#fc_frame {
  bottom: 120px !important;
}

// Payment Options

.payment-options {
  border-radius: 9px;
}

.payment-options {
  background-color: #fff;
  display: block;
  // margin: 20px 0;
  position: relative;
  border-radius: 8px;

  label {
    padding: 10px 10px;
    width: 100%;
    display: block;
    cursor: pointer;
    position: relative;
    border: 1.5px solid #fff;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    &:before {
      width: 10px;
      height: 10px;
      content: "";
      background-color: "#000";
      position: absolute;
      display: none;
      // transform: translate(-50%, -50%) scale3d(1, 1, 1);
      // transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      // background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      // border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      display: none;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    border: 1.5px solid $color-primary;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #410a76;
      box-shadow: 0px 0px 8px #410a76;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    border-radius: 8px;
  }
}

.shipping-options {
  border-radius: 9px;
}

.shipping-options {
  background-color: #fff;
  display: block;
  // margin: 20px 0;
  position: relative;
  border-radius: 8px;

  label {
    padding: 10px 10px;
    width: 100%;
    display: block;
    cursor: pointer;
    position: relative;
    border: 1.5px solid #fff;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

    &:before {
      width: 10px;
      height: 10px;
      content: "";
      background-color: #633c97;
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      // transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 15px;
      height: 15px;
      content: "";
      background-color: #fff;
      // background-image: url("data:image/svg+xml,%3Csvg width='35' height='27' viewBox='7 3 74 68' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 2px;
      top: 15%;
      display: none;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    border: 1.5px solid $color-primary;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #410a76;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    border-radius: 8px;
  }
}

.sticky-category-item {
  cursor: pointer;
  transition: ease all 0.2s;
  color: $color-primary;
  font-weight: 600;

  &:hover {
    background: $color-primary;
    color: white;
    font-weight: 600;
  }
}

.checkboxTransaction {
  display: inline-block;
  position: relative;
  border-radius: 15px;
  padding: 8px;

  label {
    padding: 5px 10px;
    width: 100%;
    display: block;
    text-align: left;
    color: #000;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-radius: 22px;
    background-color: #f4f4f4;

    &:before {
      width: 10px;
      height: 10px;
      content: "";
      background-color: $color-primary;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      // transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #410a76;
      border-color: #530c92;
      box-shadow: 0px 0px 8px #410a76;
    }
  }

  input {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// .overflow-class {
//     overflow-x: auto;
//     white-space: nowrap;
// }

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 27px;
  padding: 19% 11px;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot)
  .ant-steps-item-icon {
  margin-left: unset;
}

.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: unset;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 87%;
}

.ant-steps-label-vertical .ant-steps-item {
  width: 20%;
}

.ant-steps-small .ant-steps-item-title {
  line-height: 14px;
}

// .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon{
//     line-height: 18px;
// }

.ant-steps-small .ant-steps-item-title {
  font-size: 10px;
  font-weight: bold;
}

.ant-back-top {
  font-size: 12px;
  width: 130px;
  text-align: center;
  background: white;
  border-radius: 7px;
  padding: 10px 0px;
  color: #633c97;
  cursor: pointer;

  left: 0;
  right: 0;
  border-radius: 18px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  margin: auto;
  bottom: 70px;
}

.rounded-top-profile {
  border-top-left-radius: 2.25rem !important;
  border-top-right-radius: 2.25rem !important;
}

.button-inactive-filter {
  background-color: $color-inactive-button;
  color: $color-tertiary;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.button-active-filter {
  background-color: $color-active-button;
  color: #633c97;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.catalog-stick-category {
  top: -120px;
  transition: ease all 0.5s;
  opacity: 0;

  &.csc-show {
    top: 0px;
    opacity: 1;
  }
}

// All category style

table.category-all {
  table-layout: fixed;
  width: 100%;

  td,
  th {
    border: 1px solid #f3f4f6;
    text-align: left;
    padding: 10px;
    width: 10%;
    border-top: unset;
  }
}

.category-all-btn {
  background-color: transparent;
  border: unset;
  outline: none;
  font-weight: 700;
}

.category-all-image {
  background-color: transparent;
  border: unset;
  outline: none;
  width: 45%;
}

// End category style

.fc-widget-small:not(.fc-open) {
  display: none !important;
}

.fc-widget-small .fc_web_modal.inside-widget,
.fc-widget-small div.hotline-launcher.h-open,
.fc-widget-small.expanded-modal .hotline-launcher {
  width: 100%;
  margin: 0 !important;
}

#fc_frame.fc-widget-small.h-open-container,
#fc_frame.h-open-container {
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  margin: auto !important;
}

.hotline-launcher.h-open {
  margin: 0 !important;
}

.tumbnail-video {
  position: absolute;
  height: 100px;
  width: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.25rem;
  overflow: hidden;

  &::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7231267507002801) 0%,
      rgba(0, 0, 0, 0) 46%
    );
    width: 100%;
    height: 100%;
  }
}

.tumbnail-video-2 {
  position: absolute;
  height: 80px;
  width: 140px;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.25rem;
}

.alert-cart {
  .ant-alert-close-icon {
    top: 1px;
    right: 7px;
  }

  .ant-alert-info {
    border: 1px solid #f3f4f6;
  }
}

.alert-set-margin {
  .ant-alert-close-icon {
    top: 5px;
    right: 7px;
  }

  .ant-alert-info {
    border: 1px solid #f3f4f6;
  }
}

ol {
  padding-left: 1rem;
}

.btn.disabled,
.btn:disabled {
  background: #d0d0d0 !important;
  opacity: 1;
  border-color: transparent !important;
  color: white !important;
}

.notification-modal {
  .popup-modal-close {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: -40px;
    color: white;
    font-size: 26px;
    opacity: 0.9;
    cursor: pointer;
  }

  .ant-modal-confirm-btns {
    display: none;
  }
}

// OWL
.owl-theme .owl-dots {
  padding: 5px 32px 0px;
  display: inline-block;

  .owl-dot {
    display: inline-block;
  }

  .owl-dot span {
    background: none repeat scroll 0 0 #f4f4f4;
    border-radius: 20px;
    display: block;
    height: 5px;
    width: 5px;
    margin: 5px 2.5px;
  }

  .owl-dot.active span {
    background: none repeat scroll 0 0 #633c97;
  }
}

.text-underline {
  text-decoration: underline;
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-form-item-label {
  line-height: 1;
  padding: 0;
  label {
    font-size: 12px;
    margin-bottom: 0px;
  }
}

.ant-input {
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
}

textarea.ant-input {
  margin-top: 8px;
}

.show-scrollbar {
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #747474;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
}

.react-joyride__tooltip {
  width: auto !important;
  height: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.full-page {
  background: #f5f5f5;
  min-height: 95vh;

  &.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

input::placeholder {
  color: #d6d6d6 !important;
}

.ant-input-group-addon {
  background: none;
  border: none;
  padding: 0 5px 0 0;
}

.ant-message {
  z-index: 2001;
}

.native-page {
  .home-category-block {
    background: #FFF0E9;
  }
}

.react-modal-sheet-container {
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  right: 0px;
  height: auto !important;
}

.react-modal-sheet-backdrop {
  border: unset !important;
}

.react-modal-sheet-content {
  padding: 0px 20px 20px 20px !important;
}
// #main-content {
//   display: flex;
//   width: 100vw;
//   height: 100vh;
//   background: white;
//   align-items: center;
//   justify-content: center;
#main-content {
  display: flex;
  // width: 100vw;
  // height: 100vh;
  background: transparent;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.text {
  font-size: 11px;
  font-weight: 500;
  // margin: 10px 0 0 0;
  color: #000000;
  text-align: center;
}

svg {
  cursor: pointer;
  overflow: visible;

  #heart {
    transform-origin: center;
    animation: animateHeartOut .3s linear forwards;
  }

  #main-circ {
    transform-origin: 29.5px 29.5px;
  }
}

#checkbox {
  display: none;
}

#checkbox:checked+label svg {
  #heart {
    transform: scale(.2);
    fill: #E2264D;
    // animation: animateHeart .3s linear forwards .20s;
  }

  // #main-circ {
  //   transition: all 2s;
  //   animation: animateCircle .3s linear forwards;
  //   opacity: 1;
  // }
}

@keyframes animateCircle {
  40% {
    transform: scale(10);
    opacity: 1;
    fill: #ffffff;
  }

  55% {
    transform: scale(11);
    opacity: 1;
    fill: #ffffff;
  }

  65% {
    transform: scale(12);
    opacity: 1;
    fill: #ffffff;
  }

  75% {
    transform: scale(13);
    opacity: 1;
    fill: transparent;
    stroke: #ffffff;
    stroke-width: .5;
  }

  85% {
    transform: scale(17);
    opacity: 1;
    fill: transparent;
    stroke: #ffffff;
    stroke-width: .2;
  }

  95% {
    transform: scale(18);
    opacity: 1;
    fill: transparent;
    stroke: #ffffff;
    stroke-width: .1;
  }

  100% {
    transform: scale(19);
    opacity: 1;
    fill: transparent;
    stroke: #ffffff;
    stroke-width: 0;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(.2);
  }

  40% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animateHeartOut {
  0% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}
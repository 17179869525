.card {
    .card-body {
        padding: 8px 30px;
    }
    .card-comission {
        color: $color-alternate;
    }
    .card-points {
        color: $color-primary;
    }
border-color: #FFFFFF;
}
.close {
  position: absolute;
  top: 9%;
  right: 13%;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

@media (max-width: 320px) {
  .close {
    position: absolute;
    top: 8% !important;
    right: 10% !important;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
}

@media (max-width: 375px) {
  .close {
    position: absolute;
    top: 90% !important;
    right: 13% !important;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
}


@media (max-width: 414px) {
  .close {
    position: absolute;
    top: 10% !important;
    right: 16% !important;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
}

@media (min-height: 667px) {
  .close {
    position: absolute;
    top: 10% !important;
    right: 13% !important;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
}


@media (height: 812px) {
  .close {
    position: absolute;
    top: 9% !important;
    right: 12% !important;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
}

@media (height: 736px) {
  .close {
    position: absolute;
    top: 9% !important;
    right: 16% !important;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
}
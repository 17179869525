.brand-detail {

  &__banner {
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
  }
  
  &__card-wrapper {
    margin-top: -8%;
    width: 100%;


    .card {
      border-radius: 8px;
      padding: 16px;
      background-color: #FFF;
      box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      width: 60px;
      height: 60px;
    }
  }

}
.custom-font {
    font-family: 'Roboto';
    .nunito {
      font-family: 'Nunito';
    }
}

.roboto {
    font-family: 'Roboto';
}

.montserrat {
  font-family: 'Montserrat';
}
.badge-container-satuan {
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 8px;

  >.badge-wrapper-satuan {
    border-radius: 3px;
    >.badge-title-satuan {
      color: #693312;
      font-size: 12px;
      padding: 1px 5px 1px 5px;
      border-radius: 3px;
      background-color: #f9bc96;
    }
  }
}

.badge-container {
  border-radius: 3px;
  margin-bottom: 8px;

  >.badge-wrapper {
    border-radius: 3px;
    >.badge-title {
      color: #693312;
      font-size: 12px;
      padding: 1px 5px 1px 5px;
      border-radius: 3px;
      background-color: #f9bc96;
    }
  }
}
.ant-input {
  border: unset;
  // border-bottom: 1px solid;;
  // background-color: transparent;
  // height: 50px;
  // // color: #fff;
  // font-size: 48px;
  padding: unset !important;
}

.ant-input:focus {
  border-color: unset;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-input-number {
  border: none;
  width: 150px;
}

.ant-input-number-input {
  text-align: right;
}

.ant-input-number:hover {
  border-color: unset;
}

.ant-input-number-focused {
  box-shadow: unset;
}

.ant-input-number-handler {
  position: unset;
  display: block;
  width: unset;
  height: unset;
  overflow: unset;
  color: unset;
  font-weight: unset;
  line-height: unset;
  text-align: unset;
  -webkit-transition: unset;
  transition: unset;
}

.ant-input-number-handler-wrap {
  position: unset;
  top: unset;
  right: unset;
  width: unset;
  height: unset;
}

.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: none;
}

.ant-input-number-handler-down {
  border-top: none;
}

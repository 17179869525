.product-card-secondary {
  cursor: pointer;
  // margin-top: 1rem;
  // margin-bottom: 1rem;

  &__shape {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__catalog-icon {
    position: absolute;
    width: 20px;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    padding: 4px;
    background: white;
    z-index: 1;
  }

  &__commission {
    font-size: 9px;
    color: #fff;
    position: absolute;
    bottom: 2px;
    left: 8px;
  }

  &__name {
    padding-top: 6px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
  }

  &__price {
    padding-top: 6px;
    .currency {
      font-size: 14px;
      font-weight: 700;
      padding-right: 4px;
    }
    .price {
      font-weight: 700;
    }
  }

  &__image {
    position: relative;
    height: 100%;
  }

  &__badge {
    span {
      background-color: #f9bc96;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 3px;
      font-size: 10px;
      color: #693312;
    }
  }

  &__badge-discount {
    span {
      background-color: red;
      padding: 2px;
      margin-left: 10px;
      border-radius: 3px;
      font-size: 10px;
      color: #fff;
    }

    del {
      color: #9b9b9b;
    }
  }
}

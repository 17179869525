.best-selling {
  background-color: #fff;

  &__title-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    margin-bottom: 15px;

    span {
      font-size: 16px;
    }
  
    small {
      color: #633c97;
    }
  }
  
  .wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto;
    grid-gap: 0 14px;
    padding: 0px 20px;
    padding-right: 0;
    margin: 0 auto;
  }

  .empty {
    width: 5px;
  }
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: black;
}

.ant-tabs-ink-bar {
  background-color: black;
}

.ant-tabs-bar {
  margin: 0 0 0;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #000 !important;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: #000 !important;
  color: rgba(0, 0, 0, 0.25);
}

.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #000 !important;
  border-color: #000 !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #000 !important;
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #000 !important;
}

.ant-collapse {
  background-color: #fff;
  border: unset;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px;
}

.ant-collapse-content > .ant-collapse-content-box {
  background-color: #f8f8f8;
  border-radius: 2px;
}
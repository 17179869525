$primary-color: #633c97;

.transaction-radio {
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $primary-color;
    border-color: $primary-color;
    border-radius: 15px;
    margin-right: 11px;
  }
  .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 15px;
  }
}
// Dots PDP Slider

.slider-pdp {
    padding: 0;
    text-align: center;
    position: absolute;
    width: 100%;
    list-style: none;
    top: 90%;
}

.slider-pdp li {
    display: inline-block;
    margin: 5px;
    padding: 0;
}

.slider-pdp li button {
    border: none;
    background: #633c97;
    color: transparent;
    height: 12px;
    width: 10px;
    border-radius: 12px;
    padding: 6px;
}

.slider-pdp li.slick-active button {
    background-color: #f12b00;
    height: 12px;
    width: 35px;
}

// Dots banner slider

.slider-banner {
    padding: 0;
    padding-left: 3px;
    position: absolute;
    width: 100%;
    list-style: none;
    top: 87%;
}

.slider-banner li {
    display: inline-block;
    margin: 2px;
    padding: 0;
}

.slider-banner li button {
    border: none;
    background: #633c97;
    color: transparent;
    height: 8px;
    width: 8px;
    border-radius: 12px;
    padding: 1px;
}

.slider-banner li.slick-active button {
    background-color: #f12b00;
    height: 8px;
    width: 20px;
}


// Dots slider Kode Sahabat

.kode-sahabat-slider-share {
    padding: 0;
    position: absolute;
    width: 100%;
    list-style: none;
    top: 90%;
    text-align: center;
}

.kode-sahabat-slider-share li {
    display: inline-block;
    margin: 2px;
    padding: 0;
}

.kode-sahabat-slider-share li button {
    border: none;
    background: #633c97;
    color: transparent;
    height: 8px;
    width: 8px;
    border-radius: 12px;
    padding: 1px;
}

.kode-sahabat-slider-share li.slick-active button {
    background-color: #f12b00;
    height: 8px;
    width: 20px;
}

.kode-sahabat-slider-use {
    padding: 0;
    position: absolute;
    width: 100%;
    list-style: none;
    top: 95%;
    text-align: center;
}

.kode-sahabat-slider-use li {
    display: inline-block;
    margin: 2px;
    padding: 0;
}

.kode-sahabat-slider-use li button {
    border: none;
    background: #633c97;
    color: transparent;
    height: 8px;
    width: 8px;
    border-radius: 12px;
    padding: 1px;
}

.kode-sahabat-slider-use li.slick-active button {
    background-color: #f12b00;
    height: 8px;
    width: 20px;
}

.slider-onboarding li {
    margin: 0 2px !important;
}

.slider-onboarding li button:before {
    transition: ease all 0.5s;
    color: black;
    font-size: 4px !important;
}

.slider-onboarding li.slick-active button:before {
    color: $color-primary !important;
    font-size: 8px !important;
    opacity: 1 !important;
}

.slick-center {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
    transition: ease-in-out;
}

.slick-slide {
    transition: all 0.3s ease;
}
.ant-btn-background-ghost.ant-btn-primary{
    color: #633c97;
    background-color: transparent;
    border-color: #633c97;
    text-shadow: none;
}

.copy-btn {
    .ant-btn:focus {
    color: #633c97;
    border-color: #633c97;
    }
}

.copy-btn { 
    .ant-btn:hover {
    color: #633c97;
    border-color: #633c97;
    }
}

.copy-btn {
    .ant-btn {
      color: #633c97;
      border-color: #633c97;
    }
}
.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-gap: 0 14px;
  padding: 10px 20px;
  padding-right: 0;
  margin: 0 auto;
}

.empty {
  width: 5px;
}
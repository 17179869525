.brand-banner {
  &__banner {
    padding: 12px;
    cursor: pointer;
  }

  .slick-dots {
    text-align: unset;
    padding-left: 20px;
    bottom: -7px;
  }

  .slick-dots li button {
    width: 0;
    padding: 0;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 3px;
    height: 0px;
    /* margin: 0 5px; */
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button:before {
    font-size: 8px;
    width: 0;
    opacity: unset;
    color: #DDD;
  }

  .slick-dots li.slick-active button:before {
    color: #633c97;
  }
}

.owl-theme .owl-dots {  
  .owl-dot span {
    background: none repeat scroll 0 0 #ffffff !important;
  }

  .owl-dot.active span {
    background: none repeat scroll 0 0 #633c97 !important;
  }
}
